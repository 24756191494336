[class^="icon-"],
[class*=" icon-"] {
	position: relative;

	font-family: inherit !important;

	&[hidden] {
		display: none;
	}

	&::before {
		display: inline-block;

		font-family: 'icomoon' !important;

		width: 1em;
		height: 1em;
	}
}

i {
	display: inline-block;

	width: 1em;
	height: 1em;
}

[class^="icon-"],
[class*=" icon-"],
[class^="iconff-"],
[class*=" iconff-"],
[class^="ns-icon-"],
[class*=" ns-icon-"] {
	&[hidden] {
		display: none;
	}
}

.icon-coin {
	color: var(--color-imp-bg-7);
}
