input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

.input-element,
textarea,
input {
	outline: none;
	color: var(--color-text-500);
	font-family: inherit;
	font-size: var(--fs-m);

	border-radius: calc(0.5 * var(--spacing));
	border: none;
	box-sizing: border-box;

	background-color: var(--color-bg-180);

	padding: calc(0.75 * var(--spacing)) calc(1 * var(--spacing));
	margin: 0px;

	filter: none !important;

	box-shadow: inset 0 0 0 1px var(--color-line-230);

	&.br-0 {
		border-radius: 0;
	}

	&.transparent {
		background-color: transparent;
		box-shadow: none;
	}

	&.p-0 {
		padding: 0;
	}

	&::-ms-input-placeholder,
	&:-ms-input-placeholder &::placeholder {
		color: var(--color-text-400);
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-box-shadow: 0 0 0px 1000px var(--color-bg-180) inset,
			0 0 0 1px var(--color-line-230);

		&.focusable {
			&:focus-visible {
				box-shadow: 0 0 0px 1000px var(--color-bg-180) inset,
					0 0 0 1px var(--color-line-230), 0 0 2px 2px rgba(var(--color-primary-rgb), 0.3);
			}
		}
	}
}

label {
	color: var(--color-text-400);
	font-size: var(--fs-s);
	margin-bottom: calc(0.5 * var(--spacing));
}
