:root {
	--__light-color-line-base-2xx-rgb: 226, 226, 226; // #E2E2E2
	--__light-color-line-base-3xx-rgb: 185, 184, 188; // #B9B8BC

	--__dark-color-line-base-x3x-rgb: 78, 79, 85; // #4E4F55
	--__dark-color-line-base-x5x-rgb: 45, 46, 49; // #2D2E31

	// line-230
	--light-color-line-230-rgb: var(--__light-color-line-base-2xx-rgb);
	--dark-color-line-230-rgb: var(--__dark-color-line-base-x3x-rgb);
	--color-line-230-rgb: var(--dark-color-line-230-rgb);
	--color-line-230: rgb(var(--color-line-230-rgb));

	// line-250
	--light-color-line-250-rgb: var(--__light-color-line-base-2xx-rgb);
	--dark-color-line-250-rgb: var(--__dark-color-line-base-x5x-rgb);
	--color-line-250-rgb: var(--dark-color-line-250-rgb);
	--color-line-250: rgb(var(--color-line-250-rgb));

	// line-330
	--light-color-line-330-rgb: var(--__light-color-line-base-3xx-rgb);
	--dark-color-line-330-rgb: var(--__dark-color-line-base-x3x-rgb);
	--color-line-330-rgb: var(--dark-color-line-330-rgb);
	--color-line-330: rgb(var(--color-line-330-rgb));
}

:root[myreach-user-theme='auto'][browser-theme='light'],
:root[myreach-user-theme='light'] {
	--color-line-230-rgb: var(--light-color-line-230-rgb);
	--color-line-250-rgb: var(--light-color-line-250-rgb);
	--color-line-330-rgb: var(--light-color-line-330-rgb);
}

.reach-card {
	.reach-card-separator {
		--_gap: var(--separator-gap, calc(2.5 * var(--spacing)));

		width: calc(100% - var(--_gap));
		margin-left: var(--_gap);
		height: 1px;
		background-color: var(--color-line-230);
	}
}

.reach-card-group,
.reach-cards-group {
	& .reach-card:last-of-type {
		.reach-card-separator {
			background-color: transparent;
		}
	}
}

hr {
	color: var(--color-line-230);
	margin: 0;
	box-sizing: border-box;
}

.border {
	&,
	&-230 {
		&,
		&-t,
		&-tr,
		&-tb,
		&-tl,
		&-trb,
		&-trl,
		&-tbl {
			border-top: 1px solid var(--color-line-230);
		}

		&,
		&-r,
		&-tr,
		&-rb,
		&-rl,
		&-trb,
		&-trl,
		&-rbl {
			border-right: 1px solid var(--color-line-230);
		}

		&,
		&-b,
		&-rb,
		&-tb,
		&-bl,
		&-trb,
		&-tbl,
		&-rbl {
			border-bottom: 1px solid var(--color-line-230);
		}

		&,
		&-l,
		&-tl,
		&-rl,
		&-bl,
		&-trl,
		&-tbl,
		&-rbl {
			border-left: 1px solid var(--color-line-230);
		}
	}

	&-250 {
		&,
		&-t,
		&-tr,
		&-tb,
		&-tl,
		&-trb,
		&-trl,
		&-tbl {
			border-top: 1px solid var(--color-line-250);
		}

		&,
		&-r,
		&-tr,
		&-rb,
		&-rl,
		&-trb,
		&-trl,
		&-rbl {
			border-right: 1px solid var(--color-line-250);
		}

		&,
		&-b,
		&-rb,
		&-tb,
		&-bl,
		&-trb,
		&-tbl,
		&-rbl {
			border-bottom: 1px solid var(--color-line-250);
		}

		&,
		&-l,
		&-tl,
		&-rl,
		&-bl,
		&-trl,
		&-tbl,
		&-rbl {
			border-left: 1px solid var(--color-line-250);
		}
	}
}

.br,
.border-radius,
.radius {
	&,
	&-tr,
	&-trbr,
	&-trbl,
	&-trtl,
	&-trbrbl,
	&-trbrtl,
	&-trbltr {
		&-0q,
		&-min {
			border-top-right-radius: calc(0.25 * var(--spacing));
		}
		&-0h {
			border-top-right-radius: calc(0.5 * var(--spacing));
		}

		&-0t {
			border-top-right-radius: calc(0.75 * var(--spacing));
		}

		&-1 {
			border-top-right-radius: calc(1 * var(--spacing));
		}

		&-1h {
			border-top-right-radius: calc(1.5 * var(--spacing));
		}

		&-2 {
			border-top-right-radius: calc(2 * var(--spacing));
		}

		&-circle {
			border-top-right-radius: 50%;
		}
	}

	&,
	&-br,
	&-trbr,
	&-brbl,
	&-brtl,
	&-trbrbl,
	&-trbrtl,
	&-trbrbl {
		&-0q,
		&-min {
			border-bottom-right-radius: calc(0.25 * var(--spacing));
		}
		&-0h {
			border-bottom-right-radius: calc(0.5 * var(--spacing));
		}

		&-0t {
			border-bottom-right-radius: calc(0.75 * var(--spacing));
		}

		&-1 {
			border-bottom-right-radius: calc(1 * var(--spacing));
		}

		&-1h {
			border-bottom-right-radius: calc(1.5 * var(--spacing));
		}

		&-2 {
			border-bottom-right-radius: calc(2 * var(--spacing));
		}

		&-circle {
			border-bottom-right-radius: 50%;
		}
	}

	&,
	&-bl,
	&-trbl,
	&-brbl,
	&-bltl,
	&-trbrbl,
	&-trbltl,
	&-trbrbl {
		&-0q,
		&-min {
			border-bottom-left-radius: calc(0.25 * var(--spacing));
		}
		&-0h {
			border-bottom-left-radius: calc(0.5 * var(--spacing));
		}
		&-0t {
			border-bottom-left-radius: calc(0.75 * var(--spacing));
		}

		&-1 {
			border-bottom-left-radius: calc(1 * var(--spacing));
		}

		&-1h {
			border-bottom-left-radius: calc(1.5 * var(--spacing));
		}

		&-2 {
			border-bottom-left-radius: calc(2 * var(--spacing));
		}

		&-circle {
			border-bottom-left-radius: 50%;
		}
	}

	&,
	&-tl,
	&-trtl,
	&-brtl,
	&-bltl,
	&-trbrtl,
	&-trbltl,
	&-brbltl {
		&-0q,
		&-min {
			border-top-left-radius: calc(0.25 * var(--spacing));
		}
		&-0h {
			border-top-left-radius: calc(0.5 * var(--spacing));
		}
		&-0t {
			border-top-left-radius: calc(0.75 * var(--spacing));
		}

		&-1 {
			border-top-left-radius: calc(1 * var(--spacing));
		}

		&-1h {
			border-top-left-radius: calc(1.5 * var(--spacing));
		}

		&-2 {
			border-top-left-radius: calc(2 * var(--spacing));
		}

		&-circle {
			border-top-left-radius: 50%;
		}
	}
}
