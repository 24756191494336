button {
	border: none;
	background: none;
	color: inherit;

	font-size: inherit;
	font-weight: inherit;
	text-align: inherit;
	font-family: inherit;
	letter-spacing: inherit;
	text-transform: inherit;
	line-height: inherit;

	border-radius: 0;

	outline: none;
	margin: 0;
	padding: 0;

	cursor: pointer;

	&.disabled,
	&[disabled=''],
	&[disabled='true'] {
		cursor: not-allowed;
	}
}

.cursor {
	&-pointer {
		cursor: pointer;
	}
}

.focusable {
	&:focus-visible {
		box-shadow: 0 0 2px 2px rgba(var(--color-primary-rgb), 0.3);
	}
}
