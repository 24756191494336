/**
 * `p` is for padding.
 * `p-x` is for padding with x times --spacing.
 * `p-xh` is for padding with x.5 times --spacing (`h` is for half`).
 * `p-x-y` is for padding-Y with x times --spacing.
 *  Where y:
 *    - can be 't', 'r', 'b' or 'l' (top, right, bottom, left).
 *    - can be combined with other "y's", but following a clock-wise order starting at top (t -> r -> b -> l).
 *       e.g.: `p-1-trl` means "padding 1 at top, right and left".
 *       e.g.: `p-1-ltr` is invalid, because it is not clock-wise starting at top.
 *    - if not specified (e.g.: `p-2`, it assumes all sides).
 */

/** PADDING SAFE_AREA_INSET_TOP  */
.p-safe,
.p-safe-t,
.p-safe-tr,
.p-safe-trb,
.p-safe-trbl,
.p-safe-trl,
.p-safe-tb,
.p-safe-tbl,
.p-safe-tl
{
	padding-top: var(--safe-area-inset-top);
}

.p-safe,
.p-safe-tr,
.p-safe-trb,
.p-safe-trbl,
.p-safe-trl,
.p-safe-r,
.p-safe-rb,
.p-safe-rbl,
.p-safe-rl
{
	padding-right: var(--safe-area-inset-right);
}

.p-safe,
.p-safe-trb,
.p-safe-trbl,
.p-safe-tb,
.p-safe-tbl,
.p-safe-rb,
.p-safe-rbl,
.p-safe-b,
.p-safe-bl
{
	padding-bottom: var(--safe-area-inset-bottom);
}

.p-safe,
.p-safe-trbl,
.p-safe-trl,
.p-safe-tbl,
.p-safe-tl,
.p-safe-rbl,
.p-safe-rl,
.p-safe-bl,
.p-safe-l
{
	padding-left: var(--safe-area-inset-left);
}

 /** PADDING 0  */
.p-0,
.p-0-t,
.p-0-tr,
.p-0-trb,
.p-0-trbl,
.p-0-trl,
.p-0-tb,
.p-0-tbl,
.p-0-tl
{
	padding-top: calc(0 * var(--spacing));
}

.p-0,
.p-0-tr,
.p-0-trb,
.p-0-trbl,
.p-0-trl,
.p-0-r,
.p-0-rb,
.p-0-rbl,
.p-0-rl
{
	padding-right: calc(0 * var(--spacing));
}

.p-0,
.p-0-trb,
.p-0-trbl,
.p-0-tb,
.p-0-tbl,
.p-0-rb,
.p-0-rbl,
.p-0-b,
.p-0-bl
{
	padding-bottom: calc(0 * var(--spacing));
}

.p-0,
.p-0-trbl,
.p-0-trl,
.p-0-tbl,
.p-0-tl,
.p-0-rbl,
.p-0-rl,
.p-0-bl,
.p-0-l
{
	padding-left: calc(0 * var(--spacing));
}

 /** PADDING 0.25  */
.p-0q,
.p-0q-t,
.p-0q-tr,
.p-0q-trb,
.p-0q-trbl,
.p-0q-trl,
.p-0q-tb,
.p-0q-tbl,
.p-0q-tl
{
	padding-top: calc(0.25 * var(--spacing));
}

.p-0q,
.p-0q-tr,
.p-0q-trb,
.p-0q-trbl,
.p-0q-trl,
.p-0q-r,
.p-0q-rb,
.p-0q-rbl,
.p-0q-rl
{
	padding-right: calc(0.25 * var(--spacing));
}

.p-0q,
.p-0q-trb,
.p-0q-trbl,
.p-0q-tb,
.p-0q-tbl,
.p-0q-rb,
.p-0q-rbl,
.p-0q-b,
.p-0q-bl
{
	padding-bottom: calc(0.25 * var(--spacing));
}

.p-0q,
.p-0q-trbl,
.p-0q-trl,
.p-0q-tbl,
.p-0q-tl,
.p-0q-rbl,
.p-0q-rl,
.p-0q-bl,
.p-0q-l
{
	padding-left: calc(0.25 * var(--spacing));
}



 /** PADDING 0.5  */
.p-0h,
.p-0h-t,
.p-0h-tr,
.p-0h-trb,
.p-0h-trbl,
.p-0h-trl,
.p-0h-tb,
.p-0h-tbl,
.p-0h-tl
{
	padding-top: calc(0.5 * var(--spacing));
}

.p-0h,
.p-0h-tr,
.p-0h-trb,
.p-0h-trbl,
.p-0h-trl,
.p-0h-r,
.p-0h-rb,
.p-0h-rbl,
.p-0h-rl
{
	padding-right: calc(0.5 * var(--spacing));
}

.p-0h,
.p-0h-trb,
.p-0h-trbl,
.p-0h-tb,
.p-0h-tbl,
.p-0h-rb,
.p-0h-rbl,
.p-0h-b,
.p-0h-bl
{
	padding-bottom: calc(0.5 * var(--spacing));
}

.p-0h,
.p-0h-trbl,
.p-0h-trl,
.p-0h-tbl,
.p-0h-tl,
.p-0h-rbl,
.p-0h-rl,
.p-0h-bl,
.p-0h-l
{
	padding-left: calc(0.5 * var(--spacing));
}

 /** PADDING 0.75  */
.p-0t,
.p-0t-t,
.p-0t-tr,
.p-0t-trb,
.p-0t-trbl,
.p-0t-trl,
.p-0t-tb,
.p-0t-tbl,
.p-0t-tl
{
	padding-top: calc(0.75 * var(--spacing));
}

.p-0t,
.p-0t-tr,
.p-0t-trb,
.p-0t-trbl,
.p-0t-trl,
.p-0t-r,
.p-0t-rb,
.p-0t-rbl,
.p-0t-rl
{
	padding-right: calc(0.75 * var(--spacing));
}

.p-0t,
.p-0t-trb,
.p-0t-trbl,
.p-0t-tb,
.p-0t-tbl,
.p-0t-rb,
.p-0t-rbl,
.p-0t-b,
.p-0t-bl
{
	padding-bottom: calc(0.75 * var(--spacing));
}

.p-0t,
.p-0t-trbl,
.p-0t-trl,
.p-0t-tbl,
.p-0t-tl,
.p-0t-rbl,
.p-0t-rl,
.p-0t-bl,
.p-0t-l
{
	padding-left: calc(0.75 * var(--spacing));
}

 /** PADDING 1  */
.p-1,
.p-1-t,
.p-1-tr,
.p-1-trb,
.p-1-trbl,
.p-1-trl,
.p-1-tb,
.p-1-tbl,
.p-1-tl
{
	padding-top: calc(1 * var(--spacing));
}

.p-1,
.p-1-tr,
.p-1-trb,
.p-1-trbl,
.p-1-trl,
.p-1-r,
.p-1-rb,
.p-1-rbl,
.p-1-rl
{
	padding-right: calc(1 * var(--spacing));
}

.p-1,
.p-1-trb,
.p-1-trbl,
.p-1-tb,
.p-1-tbl,
.p-1-rb,
.p-1-rbl,
.p-1-b,
.p-1-bl
{
	padding-bottom: calc(1 * var(--spacing));
}

.p-1,
.p-1-trbl,
.p-1-trl,
.p-1-tbl,
.p-1-tl,
.p-1-rbl,
.p-1-rl,
.p-1-bl,
.p-1-l
{
	padding-left: calc(1 * var(--spacing));
}

 /** PADDING 1.5  */
.p-1h,
.p-1h-t,
.p-1h-tr,
.p-1h-trb,
.p-1h-trbl,
.p-1h-trl,
.p-1h-tb,
.p-1h-tbl,
.p-1h-tl
{
	padding-top: calc(1.5 * var(--spacing));
}

.p-1h,
.p-1h-tr,
.p-1h-trb,
.p-1h-trbl,
.p-1h-trl,
.p-1h-r,
.p-1h-rb,
.p-1h-rbl,
.p-1h-rl
{
	padding-right: calc(1.5 * var(--spacing));
}

.p-1h,
.p-1h-trb,
.p-1h-trbl,
.p-1h-tb,
.p-1h-tbl,
.p-1h-rb,
.p-1h-rbl,
.p-1h-b,
.p-1h-bl
{
	padding-bottom: calc(1.5 * var(--spacing));
}

.p-1h,
.p-1h-trbl,
.p-1h-trl,
.p-1h-tbl,
.p-1h-tl,
.p-1h-rbl,
.p-1h-rl,
.p-1h-bl,
.p-1h-l
{
	padding-left: calc(1.5 * var(--spacing));
}

 /** PADDING 2  */
.p-2,
.p-2-t,
.p-2-tr,
.p-2-trb,
.p-2-trbl,
.p-2-trl,
.p-2-tb,
.p-2-tbl,
.p-2-tl
{
	padding-top: calc(2 * var(--spacing));
}

.p-2,
.p-2-tr,
.p-2-trb,
.p-2-trbl,
.p-2-trl,
.p-2-r,
.p-2-rb,
.p-2-rbl,
.p-2-rl
{
	padding-right: calc(2 * var(--spacing));
}

.p-2,
.p-2-trb,
.p-2-trbl,
.p-2-tb,
.p-2-tbl,
.p-2-rb,
.p-2-rbl,
.p-2-b,
.p-2-bl
{
	padding-bottom: calc(2 * var(--spacing));
}

.p-2,
.p-2-trbl,
.p-2-trl,
.p-2-tbl,
.p-2-tl,
.p-2-rbl,
.p-2-rl,
.p-2-bl,
.p-2-l
{
	padding-left: calc(2 * var(--spacing));
}

 /** PADDING 2.5  */
.p-2h,
.p-2h-t,
.p-2h-tr,
.p-2h-trb,
.p-2h-trbl,
.p-2h-trl,
.p-2h-tb,
.p-2h-tbl,
.p-2h-tl
{
	padding-top: calc(2.5 * var(--spacing));
}

.p-2h,
.p-2h-tr,
.p-2h-trb,
.p-2h-trbl,
.p-2h-trl,
.p-2h-r,
.p-2h-rb,
.p-2h-rbl,
.p-2h-rl
{
	padding-right: calc(2.5 * var(--spacing));
}

.p-2h,
.p-2h-trb,
.p-2h-trbl,
.p-2h-tb,
.p-2h-tbl,
.p-2h-rb,
.p-2h-rbl,
.p-2h-b,
.p-2h-bl
{
	padding-bottom: calc(2.5 * var(--spacing));
}

.p-2h,
.p-2h-trbl,
.p-2h-trl,
.p-2h-tbl,
.p-2h-tl,
.p-2h-rbl,
.p-2h-rl,
.p-2h-bl,
.p-2h-l
{
	padding-left: calc(2.5 * var(--spacing));
}

 /** PADDING 3  */
.p-3,
.p-3-t,
.p-3-tr,
.p-3-trb,
.p-3-trbl,
.p-3-trl,
.p-3-tb,
.p-3-tbl,
.p-3-tl
{
	padding-top: calc(3 * var(--spacing));
}

.p-3,
.p-3-tr,
.p-3-trb,
.p-3-trbl,
.p-3-trl,
.p-3-r,
.p-3-rb,
.p-3-rbl,
.p-3-rl
{
	padding-right: calc(3 * var(--spacing));
}

.p-3,
.p-3-trb,
.p-3-trbl,
.p-3-tb,
.p-3-tbl,
.p-3-rb,
.p-3-rbl,
.p-3-b,
.p-3-bl
{
	padding-bottom: calc(3 * var(--spacing));
}

.p-3,
.p-3-trbl,
.p-3-trl,
.p-3-tbl,
.p-3-tl,
.p-3-rbl,
.p-3-rl,
.p-3-bl,
.p-3-l
{
	padding-left: calc(3 * var(--spacing));
}

 /** PADDING 3.5  */
.p-3h,
.p-3h-t,
.p-3h-tr,
.p-3h-trb,
.p-3h-trbl,
.p-3h-trl,
.p-3h-tb,
.p-3h-tbl,
.p-3h-tl
{
	padding-top: calc(3.5 * var(--spacing));
}

.p-3h,
.p-3h-tr,
.p-3h-trb,
.p-3h-trbl,
.p-3h-trl,
.p-3h-r,
.p-3h-rb,
.p-3h-rbl,
.p-3h-rl
{
	padding-right: calc(3.5 * var(--spacing));
}

.p-3h,
.p-3h-trb,
.p-3h-trbl,
.p-3h-tb,
.p-3h-tbl,
.p-3h-rb,
.p-3h-rbl,
.p-3h-b,
.p-3h-bl
{
	padding-bottom: calc(3.5 * var(--spacing));
}

.p-3h,
.p-3h-trbl,
.p-3h-trl,
.p-3h-tbl,
.p-3h-tl,
.p-3h-rbl,
.p-3h-rl,
.p-3h-bl,
.p-3h-l
{
	padding-left: calc(3.5 * var(--spacing));
}

 /** PADDING 4  */
.p-4,
.p-4-t,
.p-4-tr,
.p-4-trb,
.p-4-trbl,
.p-4-trl,
.p-4-tb,
.p-4-tbl,
.p-4-tl
{
	padding-top: calc(4 * var(--spacing));
}

.p-4,
.p-4-tr,
.p-4-trb,
.p-4-trbl,
.p-4-trl,
.p-4-r,
.p-4-rb,
.p-4-rbl,
.p-4-rl
{
	padding-right: calc(4 * var(--spacing));
}

.p-4,
.p-4-trb,
.p-4-trbl,
.p-4-tb,
.p-4-tbl,
.p-4-rb,
.p-4-rbl,
.p-4-b,
.p-4-bl
{
	padding-bottom: calc(4 * var(--spacing));
}

.p-4,
.p-4-trbl,
.p-4-trl,
.p-4-tbl,
.p-4-tl,
.p-4-rbl,
.p-4-rl,
.p-4-bl,
.p-4-l
{
	padding-left: calc(4 * var(--spacing));
}
