$colors-bg: (
	'light': (
		'1': (
			255,
			255,
			255,
		),
		// #FFFFFF
		'2':
			(
				244,
				245,
				247,
			),
		// #F4F5F7
		'3':
			(
				242,
				241,
				246,
			),
		// #F2F1F6
		'4':
			(
				230,
				230,
				235,
			),
		// #E6E6EB
		'5':
			(
				209,
				213,
				222,
			),
		// #D0D4DD
	),
	'dark': (
		'6': (
			62,
			62,
			68,
		),
		// #3E3E44
		'7':
			(
				44,
				44,
				50,
			),
		// #2C2C32
		'8':
			(
				28,
				28,
				34,
			),
		// #1C1C22
		'9':
			(
				10,
				10,
				16,
			),
		// #0A0A10
	),
);

:root {
	@each $light-code, $light-code-rgb in map-get($colors-bg, 'light') {
		@each $dark-code, $dark-code-rgb in map-get($colors-bg, 'dark') {
			--color-bg-#{$light-code}#{$dark-code}0-rgb: #{$dark-code-rgb};
			--color-bg-#{$light-code}#{$dark-code}0: rgb(
				var(--color-bg-#{$light-code}#{$dark-code}0-rgb)
			);

			&[myreach-user-theme='auto'][browser-theme='light'],
			&[myreach-user-theme='light'] {
				--color-bg-#{$light-code}#{$dark-code}0-rgb: #{$light-code-rgb};
			}
		}
	}
}

.bg {
	@each $light-code, $light-code-rgb in map-get($colors-bg, 'light') {
		@each $dark-code, $dark-code-rgb in map-get($colors-bg, 'dark') {
			&-#{$light-code}#{$dark-code}0 {
				background-color: var(--color-bg-#{$light-code}#{$dark-code}0);

				@for $i from 0 through 20 {
					&-#{5 * $i} {
						background-color: rgba(
							var(--color-bg-#{$light-code}#{$dark-code}0-rgb),
							0.05 * $i
						);
					}
				}

				&-on-hover {
					&:hover {
						background-color: var(--color-bg-#{$light-code}#{$dark-code}0);
					}
				}
			}
		}
	}

	background-color: var(--color-bg-180);

	&-primary {
		&,
		&-500 {
			background-color: var(--color-primary-500);

			&-20 {
				background-color: rgba(var(--color-primary-500-rgb), 0.2);
			}
		}

		&-700 {
			background-color: var(--color-primary-700);
		}
	}

	&-warn {
		&,
		&-500,
		&-540 {
			background-color: var(--color-warn-540);

			&-20 {
				background-color: rgba(var(--color-warn-540-rgb), 0.2);
			}
		}
	}

	&-line {
		&-230 {
			background-color: var(--color-line-230);
		}

		&-250 {
			background-color: var(--color-line-250);
		}

		&-330 {
			background-color: var(--color-line-330);
		}
	}
}
