.w,
.width {
	&-px {
		&-1 {
			width: 1px;
		}
	}

	&-0 {
		width: 0px;
	}

	&-50 {
		width: 50%;
	}

	&-100 {
		width: 100%;
	}

	&-icon {
		width: var(--fs-icon);
	}

	&-sp {
		&-0q {
			width: calc(0.25 * var(--spacing));
		}

		&-0h {
			width: calc(0.5 * var(--spacing));
		}

		&-1 {
			width: calc(1 * var(--spacing));
		}

		&-1h {
			width: calc(1.5 * var(--spacing));
		}

		&-2 {
			width: calc(2 * var(--spacing));
		}

		&-2h {
			width: calc(2.5 * var(--spacing));
		}

		&-3 {
			width: calc(3 * var(--spacing));
		}

		&-3h {
			width: calc(3.5 * var(--spacing));
		}

		&-4 {
			width: calc(4 * var(--spacing));
		}

		&-4h {
			width: calc(4.5 * var(--spacing));
		}

	}

	&-fs {
        &-xs {
			width: var(--fs-xs);
		}

		&-s {
			width: var(--fs-s);
		}

		&-m {
			width: var(--fs-m);
		}

		&-l {
			width: var(--fs-l);
		}

		&-xl {
			width: var(--fs-xl);
		}

		&-icon {
			width: var(--fs-icon);
		}
	}
}

.h,
.height {
	&-px {
		&-1 {
			height: 1px;
		}
	}

	&-0 {
		height: 0px;
	}

	&-50 {
		height: 50%;
	}

	&-100 {
		height: 100%;
	}

	&-icon {
		height: var(--fs-icon);
	}

	&-sp {
		&-0q {
			height: calc(0.25 * var(--spacing));
		}

		&-0h {
			height: calc(0.5 * var(--spacing));
		}

		&-1 {
			height: calc(1 * var(--spacing));
		}

		&-1h {
			height: calc(1.5 * var(--spacing));
		}

		&-2 {
			height: calc(2 * var(--spacing));
		}

		&-2h {
			height: calc(2.5 * var(--spacing));
		}

		&-3 {
			height: calc(3 * var(--spacing));
		}

		&-3h {
			height: calc(3.5 * var(--spacing));
		}

		&-4 {
			height: calc(4 * var(--spacing));
		}

		&-4h {
			height: calc(4.5 * var(--spacing));
		}

	}

	&-fs {
        &-xs {
			height: var(--fs-xs);
		}

		&-s {
			height: var(--fs-s);
		}

		&-m {
			height: var(--fs-m);
		}

		&-l {
			height: var(--fs-l);
		}

		&-xl {
			height: var(--fs-xl);
		}

		&-icon {
			height: var(--fs-icon);
		}
	}
}

.max {
	&-w,
	&-width {
		&-100 {
			max-width: 100%;
		}
	}

	&-h,
	&-height {
		&-100 {
			max-height: 100%;
		}
	}
}
