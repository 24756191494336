$colors-theme: (
	'primary': (
		'500': (
			'light': (72, 161, 238),
			'dark': (72, 161, 238),
		),
		'700': (
			'light': (48, 107, 159),
			'dark': (48, 107, 159),
		),
	),
	'warn': (
		'540': (
			'light': (208, 2, 27),
			'dark': (225, 82, 65),
		),
	),
);

:root {
	@each $theme, $theme-colors in $colors-theme {
		@each $color, $color-shades in $theme-colors {
			$dark-value: map-get($color-shades, 'dark');

			--color-#{$theme}-#{$color}-rgb: #{$dark-value};
			--color-#{$theme}-#{$color}: rgb(var(--color-#{$theme}-#{$color}-rgb));

			&[myreach-user-theme='auto'][browser-theme='light'],
			&[myreach-user-theme='light'] {
				$light-value: map-get($color-shades, 'light');
				--color-#{$theme}-#{$color}-rgb: #{$light-value};
			}
		}
	}

	--color-primary-rgb: var(--color-primary-500-rgb);
	--color-primary: rgb(var(--color-primary-rgb));
}
