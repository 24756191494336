:root {
	--color-file-rgb: 129, 130, 136; // #818288;
	--color-file: rgb(var(--color-file-rgb));

	--color-tag-rgb: 72, 161, 238; // #48A1EE;
	--color-tag: rgb(var(--color-tag-rgb));

	--color-website-rgb: 87, 192, 66; // #57C042;
	--color-website: rgb(var(--color-website-rgb));

	--color-note-rgb: 241, 89, 42; // #F1592A;
	--color-note: rgb(var(--color-note-rgb));

	--color-workspace-rgb: 245, 143, 0; // #F58F00;
	--color-workspace: rgb(var(--color-workspace-rgb));

	--color-album-rgb: 252, 198, 0; // #FCC600;
	--color-album: rgb(var(--color-album-rgb));
}

.fc {
	&-FILE {
		color: var(--color-file);
	}

	&-TAG {
		color: var(--color-tag);
	}

	&-WEBSITE {
		color: var(--color-website);
	}

	&-NOTE {
		color: var(--color-note);
	}

	&-WORKSPACE {
		color: var(--color-workspace);
	}

	&-ALBUM {
		color: var(--color-album);
	}
}

.bg {
	&-FILE {
		&-20 {
			background-color: rgba(var(--color-file-rgb), 0.2);
		}
	}

	&-TAG {
		&-20 {
			background-color: rgba(var(--color-tag-rgb), 0.2);
		}
	}

	&-WEBSITE {
		&-20 {
			background-color: rgba(var(--color-website-rgb), 0.2);
		}
	}

	&-NOTE {
		&-20 {
			background-color: rgba(var(--color-note-rgb), 0.2);
		}
	}

	&-WORKSPACE {
		&-20 {
			background-color: rgba(var(--color-workspace-rgb), 0.2);
		}
	}

	&-ALBUM {
		&-20 {
			background-color: rgba(var(--color-album-rgb), 0.2);
		}
	}
}

.border {
	&-FILE {
		border-color: var(--color-file);
	}

	&-TAG {
		border-color: var(--color-tag);
	}

	&-WEBSITE {
		border-color: var(--color-website);
	}

	&-NOTE {
		border-color: var(--color-note);
	}

	&-WORKSPACE {
		border-color: var(--color-workspace);
	}

	&-ALBUM {
		border-color: var(--color-album);
	}
}
