a {
	text-decoration: none;
	
	&.text-anchor {
		color: var(--color-primary-500);

		&:hover {
			text-decoration: underline;
		}
	}
}
