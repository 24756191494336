:root {
	// text-400 - #8B92A2 / #8B92A2
	--light-color-text-400-rgb: 139, 146, 162;
	--dark-color-text-400-rgb: 139, 146, 162;
	--color-text-400-rgb: var(--dark-color-text-400-rgb);
	--color-text-400: rgb(var(--color-text-400-rgb));

	// text-500 - #1B2A4A / #E3E3E3
	--light-color-text-500-rgb: 27, 42, 74;
	--dark-color-text-500-rgb: 227, 227, 227;
	--color-text-500-rgb: var(--dark-color-text-500-rgb);
	--color-text-500: rgb(var(--color-text-500-rgb));

	// text-white
	--color-text-white-rgb: 255, 255, 255;
	--color-text-white: rgb(var(--color-text-white-rgb));

	// alias
	--color-text-light-rgb: var(--color-text-400-rgb);
	--color-text-light: rgb(var(--color-text-light-rgb));
	--color-text-rgb: var(--color-text-500-rgb);
	--color-text: rgb(var(--color-text-rgb));

	// FONT_SIZE
	--fs-icon: calc((24 / 16) * 1rem);

	--fs-xxl: calc((22 / 16) * 1rem);
	--fs-xl: calc((20 / 16) * 1rem);
	--fs-l: calc((18 / 16) * 1rem);
	--fs-m: calc((16 / 16) * 1rem);
	--fs-s: calc((14 / 16) * 1rem);
	--fs-xs: calc((12 / 16) * 1rem);
	--fs-xxs: calc((10 / 16) * 1rem);

	// FONT_WEIGHT
	--fw-thin: 200;
	--fw-light: 300;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-bold: 700;
	--fw-black: 900;

	--font-line-height: 1.2;
}

:root[myreach-user-theme='auto'][browser-theme='light'],
:root[myreach-user-theme='light'] {
	--color-text-400-rgb: var(--light-color-text-400-rgb);
	--color-text-500-rgb: var(--light-color-text-500-rgb);
}

html {
	font-size: var(--fs-m);
	color: var(--color-text-500);

	font-family: 'Helvetica Neue', 'Roboto', Arial, sans-serif;
	line-height: var(--font-line-height);
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

[class^='icon-'],
[class*=' icon-'] {
	font-size: var(--fs-icon);

	&.fs {
		&-xxl {
			font-size: var(--fs-xxl);
		}

		&-xl {
			font-size: var(--fs-xl);
		}

		&-l {
			font-size: var(--fs-l);
		}

		&-m {
			font-size: var(--fs-m);
		}

		&-s {
			font-size: var(--fs-s);
		}

		&-xs {
			font-size: var(--fs-xs);
		}

		&-xxs {
			font-size: var(--fs-xxs);
		}

		&-icon {
			font-size: var(--fs-icon);
		}

		&-icon-l {
			font-size: var(--fs-icon-l);
		}

		&-em {
			font-size: 1em;
		}
	}
}

.lh,
.l-h,
.line-h,
.line-height,
.l-height {
	&-1 {
		line-height: 1em;
	}
}

.fs {
	&-xxl {
		font-size: var(--fs-xxl);
	}

	&-xl {
		font-size: var(--fs-xl);
	}

	&-l {
		font-size: var(--fs-l);
	}

	&-m {
		font-size: var(--fs-m);
	}

	&-s {
		font-size: var(--fs-s);
	}

	&-xs {
		font-size: var(--fs-xs);
	}

	&-xxs {
		font-size: var(--fs-xxs);
	}

	&-icon {
		font-size: var(--fs-icon);
	}

	&-em {
		font-size: 1em;
	}
}

.fc {
	&-light,
	&-400 {
		color: var(--color-text-400);
	}

	&,
	&-500 {
		color: var(--color-text-500);
	}

	&-primary {
		&,
		&-500 {
			color: var(--color-primary-500);
		}
	}

	&-warn,
	&-warning {
		&,
		&-540 {
			color: var(--color-warn-540);
		}
	}

	&-white {
		color: var(--color-text-white);
	}
}

.fa,
.ta,
.falign,
.talign,
.text-align {
	&-l,
	&-left {
		text-align: left;
	}

	&-c,
	&-center {
		text-align: center;
	}

	&-r,
	&-right {
		text-align: right;
	}
}

.fw {
	&-t,
	&-thin,
	&-200 {
		font-weight: var(--fw-thin);
	}

	&-l,
	&-light,
	&-300 {
		font-weight: var(--fw-light);
	}

	&-r,
	&-regular,
	&-400 {
		font-weight: var(--fw-regular);
	}

	&-m,
	&-medium,
	&-500 {
		font-weight: var(--fw-medium);
	}

	&-b,
	&-bold,
	&-700 {
		font-weight: var(--fw-bold);
	}

	&-xb,
	&-bl,
	&-black,
	&-900 {
		font-weight: var(--fw-black);
	}
}
