@media (hover: hover) {
	.visible-on-hover {
		visibility: hidden;
	}

	.visible-on-hover-parent:hover {
		.visible-on-hover {
			visibility: visible;
		}
	}

	.visible-on-hover-parent:not(:hover) {
		.visible-on-hover {
			visibility: hidden;
		}
	}
}

@media not (hover: hover) {
	.invisible-on-mobile {
		visibility: hidden;
	}
}

.copy-button {
	&:not(:focus) {
		.visible-on-active {
			display: none !important;
		}
	}

	&:focus {
		.hidden-on-active {
			display: none !important;
		}
	}
}
