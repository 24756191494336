/**
 * `m` is for margin.
 * `m-x` is for margin with x times --spacing.
 * `m-xh` is for margin with x.5 times --spacing (`h` is for half`).
 * `m-x-y` is for margin-Y with x times --spacing.
 *  Where y:
 *    - can be 't', 'r', 'b' or 'l' (top, right, bottom, left).
 *    - can be combined with other "y's", but following a clock-wise order starting at top (t -> r -> b -> l).
 *       e.g.: `m-1-trl` means "margin 1 at top, right and left".
 *       e.g.: `m-1-ltr` is invalid, because it is not clock-wise starting at top.
 *    - if not specified (e.g.: `m-2`, it assumes all sides).
 */

/** MARGIN NEGATIVE 1  */
.m-neg1,
.m-neg1-t,
.m-neg1-tr,
.m-neg1-trb,
.m-neg1-trbl,
.m-neg1-trl,
.m-neg1-tb,
.m-neg1-tbl,
.m-neg1-tl
{
	margin-top: calc(-1 * var(--spacing));
}

.m-neg1,
.m-neg1-tr,
.m-neg1-trb,
.m-neg1-trbl,
.m-neg1-trl,
.m-neg1-r,
.m-neg1-rb,
.m-neg1-rbl,
.m-neg1-rl
{
	margin-right: calc(-1 * var(--spacing));
}

.m-neg1,
.m-neg1-trb,
.m-neg1-trbl,
.m-neg1-tb,
.m-neg1-tbl,
.m-neg1-rb,
.m-neg1-rbl,
.m-neg1-b,
.m-neg1-bl
{
	margin-bottom: calc(-1 * var(--spacing));
}

.m-neg1,
.m-neg1-trbl,
.m-neg1-trl,
.m-neg1-tbl,
.m-neg1-tl,
.m-neg1-rbl,
.m-neg1-rl,
.m-neg1-bl,
.m-neg1-l
{
	margin-left: calc(-1 * var(--spacing));
}

/** MARGIN SAFE_AREA_INSET_TOP  */
.m-safe,
.m-safe-t,
.m-safe-tr,
.m-safe-trb,
.m-safe-trbl,
.m-safe-trl,
.m-safe-tb,
.m-safe-tbl,
.m-safe-tl
{
	margin-top: var(--safe-area-inset-top);
}

.m-safe,
.m-safe-tr,
.m-safe-trb,
.m-safe-trbl,
.m-safe-trl,
.m-safe-r,
.m-safe-rb,
.m-safe-rbl,
.m-safe-rl
{
	margin-right: var(--safe-area-inset-right);
}

.m-safe,
.m-safe-trb,
.m-safe-trbl,
.m-safe-tb,
.m-safe-tbl,
.m-safe-rb,
.m-safe-rbl,
.m-safe-b,
.m-safe-bl
{
	margin-bottom: var(--safe-area-inset-bottom);
}

.m-safe,
.m-safe-trbl,
.m-safe-trl,
.m-safe-tbl,
.m-safe-tl,
.m-safe-rbl,
.m-safe-rl,
.m-safe-bl,
.m-safe-l
{
	margin-left: var(--safe-area-inset-left);
}

 /** MARGIN AUTO  */
 .m-auto,
 .m-auto-t,
 .m-auto-tr,
 .m-auto-trb,
 .m-auto-trbl,
 .m-auto-trl,
 .m-auto-tb,
 .m-auto-tbl,
 .m-auto-tl
 {
	 margin-top: auto;
 }

 .m-auto,
 .m-auto-tr,
 .m-auto-trb,
 .m-auto-trbl,
 .m-auto-trl,
 .m-auto-r,
 .m-auto-rb,
 .m-auto-rbl,
 .m-auto-rl
 {
	 margin-right: auto;
 }

 .m-auto,
 .m-auto-trb,
 .m-auto-trbl,
 .m-auto-tb,
 .m-auto-tbl,
 .m-auto-rb,
 .m-auto-rbl,
 .m-auto-b,
 .m-auto-bl
 {
	 margin-bottom: auto;
 }

 .m-auto,
 .m-auto-trbl,
 .m-auto-trl,
 .m-auto-tbl,
 .m-auto-tl,
 .m-auto-rbl,
 .m-auto-rl,
 .m-auto-bl,
 .m-auto-l
 {
	 margin-left: auto;
 }

 /** MARGIN 0  */
.m-0,
.m-0-t,
.m-0-tr,
.m-0-trb,
.m-0-trbl,
.m-0-trl,
.m-0-tb,
.m-0-tbl,
.m-0-tl
{
	margin-top: calc(0 * var(--spacing));
}

.m-0,
.m-0-tr,
.m-0-trb,
.m-0-trbl,
.m-0-trl,
.m-0-r,
.m-0-rb,
.m-0-rbl,
.m-0-rl
{
	margin-right: calc(0 * var(--spacing));
}

.m-0,
.m-0-trb,
.m-0-trbl,
.m-0-tb,
.m-0-tbl,
.m-0-rb,
.m-0-rbl,
.m-0-b,
.m-0-bl
{
	margin-bottom: calc(0 * var(--spacing));
}

.m-0,
.m-0-trbl,
.m-0-trl,
.m-0-tbl,
.m-0-tl,
.m-0-rbl,
.m-0-rl,
.m-0-bl,
.m-0-l
{
	margin-left: calc(0 * var(--spacing));
}

 /** MARGIN 0.25  */
.m-0q,
.m-0q-t,
.m-0q-tr,
.m-0q-trb,
.m-0q-trbl,
.m-0q-trl,
.m-0q-tb,
.m-0q-tbl,
.m-0q-tl
{
	margin-top: calc(0.25 * var(--spacing));
}

.m-0q,
.m-0q-tr,
.m-0q-trb,
.m-0q-trbl,
.m-0q-trl,
.m-0q-r,
.m-0q-rb,
.m-0q-rbl,
.m-0q-rl
{
	margin-right: calc(0.25 * var(--spacing));
}

.m-0q,
.m-0q-trb,
.m-0q-trbl,
.m-0q-tb,
.m-0q-tbl,
.m-0q-rb,
.m-0q-rbl,
.m-0q-b,
.m-0q-bl
{
	margin-bottom: calc(0.25 * var(--spacing));
}

.m-0q,
.m-0q-trbl,
.m-0q-trl,
.m-0q-tbl,
.m-0q-tl,
.m-0q-rbl,
.m-0q-rl,
.m-0q-bl,
.m-0q-l
{
	margin-left: calc(0.25 * var(--spacing));
}



 /** MARGIN 0.5  */
.m-0h,
.m-0h-t,
.m-0h-tr,
.m-0h-trb,
.m-0h-trbl,
.m-0h-trl,
.m-0h-tb,
.m-0h-tbl,
.m-0h-tl
{
	margin-top: calc(0.5 * var(--spacing));
}

.m-0h,
.m-0h-tr,
.m-0h-trb,
.m-0h-trbl,
.m-0h-trl,
.m-0h-r,
.m-0h-rb,
.m-0h-rbl,
.m-0h-rl
{
	margin-right: calc(0.5 * var(--spacing));
}

.m-0h,
.m-0h-trb,
.m-0h-trbl,
.m-0h-tb,
.m-0h-tbl,
.m-0h-rb,
.m-0h-rbl,
.m-0h-b,
.m-0h-bl
{
	margin-bottom: calc(0.5 * var(--spacing));
}

.m-0h,
.m-0h-trbl,
.m-0h-trl,
.m-0h-tbl,
.m-0h-tl,
.m-0h-rbl,
.m-0h-rl,
.m-0h-bl,
.m-0h-l
{
	margin-left: calc(0.5 * var(--spacing));
}

/** MARGIN 0.75  */
.m-0t,
.m-0t-t,
.m-0t-tr,
.m-0t-trb,
.m-0t-trbl,
.m-0t-trl,
.m-0t-tb,
.m-0t-tbl,
.m-0t-tl
{
   margin-top: calc(0.75 * var(--spacing));
}

.m-0t,
.m-0t-tr,
.m-0t-trb,
.m-0t-trbl,
.m-0t-trl,
.m-0t-r,
.m-0t-rb,
.m-0t-rbl,
.m-0t-rl
{
   margin-right: calc(0.75 * var(--spacing));
}

.m-0t,
.m-0t-trb,
.m-0t-trbl,
.m-0t-tb,
.m-0t-tbl,
.m-0t-rb,
.m-0t-rbl,
.m-0t-b,
.m-0t-bl
{
   margin-bottom: calc(0.75 * var(--spacing));
}

.m-0t,
.m-0t-trbl,
.m-0t-trl,
.m-0t-tbl,
.m-0t-tl,
.m-0t-rbl,
.m-0t-rl,
.m-0t-bl,
.m-0t-l
{
   margin-left: calc(0.75 * var(--spacing));
}

 /** MARGIN 1  */
.m-1,
.m-1-t,
.m-1-tr,
.m-1-trb,
.m-1-trbl,
.m-1-trl,
.m-1-tb,
.m-1-tbl,
.m-1-tl
{
	margin-top: calc(1 * var(--spacing));
}

.m-1,
.m-1-tr,
.m-1-trb,
.m-1-trbl,
.m-1-trl,
.m-1-r,
.m-1-rb,
.m-1-rbl,
.m-1-rl
{
	margin-right: calc(1 * var(--spacing));
}

.m-1,
.m-1-trb,
.m-1-trbl,
.m-1-tb,
.m-1-tbl,
.m-1-rb,
.m-1-rbl,
.m-1-b,
.m-1-bl
{
	margin-bottom: calc(1 * var(--spacing));
}

.m-1,
.m-1-trbl,
.m-1-trl,
.m-1-tbl,
.m-1-tl,
.m-1-rbl,
.m-1-rl,
.m-1-bl,
.m-1-l
{
	margin-left: calc(1 * var(--spacing));
}

 /** MARGIN 1.5  */
.m-1h,
.m-1h-t,
.m-1h-tr,
.m-1h-trb,
.m-1h-trbl,
.m-1h-trl,
.m-1h-tb,
.m-1h-tbl,
.m-1h-tl
{
	margin-top: calc(1.5 * var(--spacing));
}

.m-1h,
.m-1h-tr,
.m-1h-trb,
.m-1h-trbl,
.m-1h-trl,
.m-1h-r,
.m-1h-rb,
.m-1h-rbl,
.m-1h-rl
{
	margin-right: calc(1.5 * var(--spacing));
}

.m-1h,
.m-1h-trb,
.m-1h-trbl,
.m-1h-tb,
.m-1h-tbl,
.m-1h-rb,
.m-1h-rbl,
.m-1h-b,
.m-1h-bl
{
	margin-bottom: calc(1.5 * var(--spacing));
}

.m-1h,
.m-1h-trbl,
.m-1h-trl,
.m-1h-tbl,
.m-1h-tl,
.m-1h-rbl,
.m-1h-rl,
.m-1h-bl,
.m-1h-l
{
	margin-left: calc(1.5 * var(--spacing));
}

 /** MARGIN 2  */
.m-2,
.m-2-t,
.m-2-tr,
.m-2-trb,
.m-2-trbl,
.m-2-trl,
.m-2-tb,
.m-2-tbl,
.m-2-tl
{
	margin-top: calc(2 * var(--spacing));
}

.m-2,
.m-2-tr,
.m-2-trb,
.m-2-trbl,
.m-2-trl,
.m-2-r,
.m-2-rb,
.m-2-rbl,
.m-2-rl
{
	margin-right: calc(2 * var(--spacing));
}

.m-2,
.m-2-trb,
.m-2-trbl,
.m-2-tb,
.m-2-tbl,
.m-2-rb,
.m-2-rbl,
.m-2-b,
.m-2-bl
{
	margin-bottom: calc(2 * var(--spacing));
}

.m-2,
.m-2-trbl,
.m-2-trl,
.m-2-tbl,
.m-2-tl,
.m-2-rbl,
.m-2-rl,
.m-2-bl,
.m-2-l
{
	margin-left: calc(2 * var(--spacing));
}

 /** MARGIN 2.5  */
.m-2h,
.m-2h-t,
.m-2h-tr,
.m-2h-trb,
.m-2h-trbl,
.m-2h-trl,
.m-2h-tb,
.m-2h-tbl,
.m-2h-tl
{
	margin-top: calc(2.5 * var(--spacing));
}

.m-2h,
.m-2h-tr,
.m-2h-trb,
.m-2h-trbl,
.m-2h-trl,
.m-2h-r,
.m-2h-rb,
.m-2h-rbl,
.m-2h-rl
{
	margin-right: calc(2.5 * var(--spacing));
}

.m-2h,
.m-2h-trb,
.m-2h-trbl,
.m-2h-tb,
.m-2h-tbl,
.m-2h-rb,
.m-2h-rbl,
.m-2h-b,
.m-2h-bl
{
	margin-bottom: calc(2.5 * var(--spacing));
}

.m-2h,
.m-2h-trbl,
.m-2h-trl,
.m-2h-tbl,
.m-2h-tl,
.m-2h-rbl,
.m-2h-rl,
.m-2h-bl,
.m-2h-l
{
	margin-left: calc(2.5 * var(--spacing));
}

 /** MARGIN 3  */
.m-3,
.m-3-t,
.m-3-tr,
.m-3-trb,
.m-3-trbl,
.m-3-trl,
.m-3-tb,
.m-3-tbl,
.m-3-tl
{
	margin-top: calc(3 * var(--spacing));
}

.m-3,
.m-3-tr,
.m-3-trb,
.m-3-trbl,
.m-3-trl,
.m-3-r,
.m-3-rb,
.m-3-rbl,
.m-3-rl
{
	margin-right: calc(3 * var(--spacing));
}

.m-3,
.m-3-trb,
.m-3-trbl,
.m-3-tb,
.m-3-tbl,
.m-3-rb,
.m-3-rbl,
.m-3-b,
.m-3-bl
{
	margin-bottom: calc(3 * var(--spacing));
}

.m-3,
.m-3-trbl,
.m-3-trl,
.m-3-tbl,
.m-3-tl,
.m-3-rbl,
.m-3-rl,
.m-3-bl,
.m-3-l
{
	margin-left: calc(3 * var(--spacing));
}

 /** MARGIN 3.5  */
.m-3h,
.m-3h-t,
.m-3h-tr,
.m-3h-trb,
.m-3h-trbl,
.m-3h-trl,
.m-3h-tb,
.m-3h-tbl,
.m-3h-tl
{
	margin-top: calc(3.5 * var(--spacing));
}

.m-3h,
.m-3h-tr,
.m-3h-trb,
.m-3h-trbl,
.m-3h-trl,
.m-3h-r,
.m-3h-rb,
.m-3h-rbl,
.m-3h-rl
{
	margin-right: calc(3.5 * var(--spacing));
}

.m-3h,
.m-3h-trb,
.m-3h-trbl,
.m-3h-tb,
.m-3h-tbl,
.m-3h-rb,
.m-3h-rbl,
.m-3h-b,
.m-3h-bl
{
	margin-bottom: calc(3.5 * var(--spacing));
}

.m-3h,
.m-3h-trbl,
.m-3h-trl,
.m-3h-tbl,
.m-3h-tl,
.m-3h-rbl,
.m-3h-rl,
.m-3h-bl,
.m-3h-l
{
	margin-left: calc(3.5 * var(--spacing));
}

 /** MARGIN 4  */
.m-4,
.m-4-t,
.m-4-tr,
.m-4-trb,
.m-4-trbl,
.m-4-trl,
.m-4-tb,
.m-4-tbl,
.m-4-tl
{
	margin-top: calc(4 * var(--spacing));
}

.m-4,
.m-4-tr,
.m-4-trb,
.m-4-trbl,
.m-4-trl,
.m-4-r,
.m-4-rb,
.m-4-rbl,
.m-4-rl
{
	margin-right: calc(4 * var(--spacing));
}

.m-4,
.m-4-trb,
.m-4-trbl,
.m-4-tb,
.m-4-tbl,
.m-4-rb,
.m-4-rbl,
.m-4-b,
.m-4-bl
{
	margin-bottom: calc(4 * var(--spacing));
}

.m-4,
.m-4-trbl,
.m-4-trl,
.m-4-tbl,
.m-4-tl,
.m-4-rbl,
.m-4-rl,
.m-4-bl,
.m-4-l
{
	margin-left: calc(4 * var(--spacing));
}
