:root {
	color-scheme: light dark;

	--z-index-max: 2147483647;
}

html,
body {
	width: 100%;
	height: 100%;

	margin: 0;
}

p {
	margin: 0;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.d,
.display {
	&-b,
	&-block {
		display: block;
	}

	&-none {
		display: none;
	}
}

img {
	// This is for the `alt` property.
	text-align: center;

	display: flex;
	align-items: center;
	justify-content: center;
}

.ov,
.overflow {
	&-a,
	&-auto {
		overflow: auto;
	}

	&-h,
	&-hidden {
		overflow: hidden;
	}
}

.zi,
.zindex,
.z-index {
	&-0 {
		z-index: 0;
	}

	&-1 {
		z-index: 1;
	}
}

.unclickable {
	pointer-events: none;
}

.clickable {
	pointer-events: auto;
}

.ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.loading-line {
	position: relative;

	&.pos-abs {
		position: absolute;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;

		height: 100%;
		width: 100px;
		max-width: 33%;

		transform: translateX(-100%);

		background-color: rgba(var(--color-primary-rgb), 0.5);

		animation: loading-line 1s infinite linear;
	}
}

@keyframes loading-line {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(0px);
		left: 100%;
	}
}
