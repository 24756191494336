:root {
	--shadow-1: 4px 12px 24px 0px rgba(12, 30, 70, 0.1), 0px 8px 12px 0px rgba(17, 42, 96, 0.04),
		0px 4px 6px 0px rgba(13, 34, 80, 0.04), 0px 2px 4px 0px rgba(9, 22, 70, 0.02),
		0px 1px 2px 0px rgba(0, 0, 0, 0.04);
}

.shadow {
	&-1 {
		box-shadow: var(--shadow-1);
	}
}
