// Base style file

@import '@reach/icons/dist/main-icons/style.css';

@import 'spacing';

@import 'anchors';
@import 'background';
@import 'base';
@import 'border';
@import 'buttons';
@import 'flex';
@import 'headers';
@import 'icon-override';
@import 'inputs';
@import 'lists';
@import 'material';
@import 'nodes';
@import 'position';
@import 'reset';
@import 'shadow';
@import 'sizes';
@import 'text';
@import 'theme';
@import 'visibility';
