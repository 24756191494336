:root {
	--fs-h1: calc((22 / 16) * 1rem);
	--fs-h2: calc((20 / 16) * 1rem);
	--fs-h3: calc((18 / 16) * 1rem);
	--fs-h4: calc((16 / 16) * 1rem);
}

h1,
h2,
h3,
h4 {
	margin: 0;
}

.fs-h1,
h1 {
	font-size: var(--fs-h1);
}

.fs-h2,
h2 {
	font-size: var(--fs-h1);
}

.fs-h3,
h3 {
	font-size: var(--fs-h1);
}

.fs-h4,
h4 {
	font-size: var(--fs-h1);
}
