.pos {
	&-rel,
	&-relative {
		position: relative;
	}

	&-abs,
	&-absolute {
		position: absolute;
	}

	&-fix,
	&-fixed {
		position: fixed;
	}

	&-sticky {
		position: sticky;
	}

	&-t,
	&-top {
		@for $i from 0 through 4 {
			&-#{$i} {
				top: calc(#{$i} * var(--spacing));
			}

			&-#{$i}h {
				top: calc((#{$i} + 0.5) * var(--spacing));
			}
		}

		&-100 {
			top: 100%;
		}
	}

	&-b,
	&-bottom {
		@for $i from 0 through 4 {
			&-#{$i} {
				bottom: calc(#{$i} * var(--spacing));
			}

			&-#{$i}h {
				bottom: calc((#{$i} + 0.5) * var(--spacing));
			}
		}

		&-100 {
			bottom: 100%;
		}
	}

	&-l,
	&-left {
		@for $i from 0 through 4 {
			&-#{$i} {
				left: calc(#{$i} * var(--spacing));
			}

			&-#{$i}h {
				left: calc((#{$i} + 0.5) * var(--spacing));
			}
		}

		&-100 {
			left: 100%;
		}
	}

	&-r,
	&-right {
		@for $i from 0 through 4 {
			&-#{$i} {
				right: calc(#{$i} * var(--spacing));
			}

			&-#{$i}h {
				right: calc((#{$i} + 0.5) * var(--spacing));
			}
		}

		&-100 {
			right: 100%;
		}
	}
}
